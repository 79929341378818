import { Box } from "@chakra-ui/react";
import VerticalText from "./VerticalText";
import { BtRepresentationFull } from "../../core/types";
import {getCategoryColor} from "../../core/helpers";
import { useEffect, useState } from "react";

interface RoomAreaProps {
    rep: BtRepresentationFull;
    zoomLevel: number;
    toggleSeatSelection: (index: number) => void;
}

function RoomArea(props : RoomAreaProps) {
    //const [areaWidth, setAreaWidth] = useState(0);
    const [areaHeight, setAreaHeight] = useState(0);
    const [deltaX, setDeltaX] = useState(0);
    const [deltaY, setDeltaY] = useState(0);
    const [transformationFactor, setTransformationFactor] = useState(0);


    const {rep, zoomLevel, toggleSeatSelection} = props;
    

    const setHeight = (tf?: number) => {
        let transform = tf ? tf : transformationFactor;
        let seatWithGreatestY = rep.seats.length > 0 ? rep.seats.reduce((max, seat) => max.y > seat.y ? max : seat): {y:0, h:0};
        let objWithGreatestY = rep.objects.length > 0 ? rep.objects.reduce((max, obj) => max.y + max.h > obj.y + obj.h ? max : obj): {y:0, h:0};

        let seatMinY =  rep.seats.length > 0 ? rep.seats.reduce((min, seat) => min.y < seat.y ? min : seat): {y:0};
        let objMinY =  rep.objects.length > 0 ? rep.objects.reduce((min, obj) => min.y < obj.y ? min : obj): {y:0};

        let dy = Math.min(seatMinY.y, objMinY.y) * transform;
        //console.log("dy", dy);
        setDeltaY(dy);

        let ah = (Math.max(seatWithGreatestY.y + 1, objWithGreatestY.y + objWithGreatestY.h)) * transform;
        //console.log("ah", ah-dy);
        setAreaHeight(ah-dy);
    };

    const setWidth = (tf?: number) => {
        let transform = tf ? tf : transformationFactor;
        //let seatWithGreatestX =  rep.seats.length > 0 ? rep.seats.reduce((max, seat) => max.x > seat.x ? max : seat): {x:0};
        //let objWithGreatestX = rep.objects.length > 0 ? rep.objects.reduce((max, obj) => max > obj.x + obj.w ? max : obj.x + obj.w, 0) : 0;
        let seatMinX =  rep.seats.length > 0 ? rep.seats.reduce((min, seat) => min.x < seat.x ? min : seat): {x:0};
        let objMinX =  rep.objects.length > 0 ? rep.objects.reduce((min, obj) => min.x < obj.x ? min : obj): {x:0};

        let dx = Math.min(seatMinX.x, objMinX.x)*transform;
        //console.log("dseatWithGreatestX", seatWithGreatestX);
        setDeltaX(dx);
        //let aw = (Math.max((seatWithGreatestX.x + 1), objWithGreatestX))* transform;
        //console.log("aw", aw-dx);
        //setAreaWidth(aw-dx);
    };

     useEffect(() => {
        setTransformationFactor(zoomLevel * 20);
        //console.log("zoomLevel", zoomLevel, "transformationFactor", transformationFactor);
        setWidth(zoomLevel * 20);
        setHeight(zoomLevel * 20);
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

    return (
        <Box w={941} h={areaHeight} position="relative" css={`transform-origin: top left;`} transform={`scale(${zoomLevel})`} transition="transform 1s ease, left 1s ease, top 1s ease" >
        {rep.seats.map((seat, index) => (
            <Box zIndex={5}
                key={index}
                position="absolute"
                left={`${seat.x * transformationFactor - deltaX}px`}
                top={`${seat.y * transformationFactor - deltaY}px`}
                width={`${transformationFactor}px`}
                height={`${transformationFactor}px`}
                padding="2px">
                <Box
                    width="100%"
                    height="100%"
                    backgroundColor={seat.isAvailable ? (seat.isSelected ? 'orange' : getCategoryColor(seat.defaultCategory)) : '#e5e5e5'}
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color={seat.isSelected ? 'black' : 'white'}
                    fontSize={10}
                    onClick={() => seat.isAvailable && toggleSeatSelection(index)}
                    _hover={seat.isAvailable ? {border: '2px solid black'} : {}}
                    cursor={seat.isAvailable ? "pointer" : "default"}>
                    {false && seat.number}
                </Box>
            </Box>
        ))}

        {rep.objects.map((obj, index) => {
            return (
                <Box p={'1px'} key={index}>
                <Box zIndex={obj.zIndex}
                    position="absolute"
                    left={`${obj.x * transformationFactor - deltaX}px`}
                    top={`${obj.y * transformationFactor - deltaY}px`}
                    width={`${obj.w * transformationFactor-2}px`}
                    height={`${obj.h * transformationFactor-2}px`}
                    backgroundColor={obj.backColor}
                    backgroundImage={obj.backPicture + "/o"}
                    backgroundSize={'100%'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"

                    overflow={'hidden'}>
                    {obj.isVerticalText && <VerticalText  gridSize={transformationFactor} text={obj.text}></VerticalText>}
                    {!obj.isVerticalText && <Box fontSize={transformationFactor/2}>{obj.text}</Box>}
                </Box>
                </Box>
            );
        })}
    </Box>
    );

}

export default RoomArea;